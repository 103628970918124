import lc_veterinaria from '@img/lc_veterinaria.webp';
import lc_dog_park from '@img/lc_dog_park.webp';
import lc_park_pet_friendly from '@img/lc_park_pet_friendly.webp';
import lc_pet_store from '@img/lc_pet_store.webp';
import marker from '@img/marker.webp';
import lc_training from '@img/lc_training.webp';
import lc_groomer from '@img/lc_groomer.webp';
import lc_breeding from '@img/lc_breeding.webp';
import lc_local from '@img/lc_local.webp';
import lc_other from '@img/lc_other.webp';
import placeholderPetshop from '@img/placeholder_petshop.webp';
import placeholderVet from '@img/placeholder_vet.webp';
import placeholderPark from '@img/placeholder_park.webp';
import placeholderParkDogFriendly from '@img/placeholder_park_dog_friendly.webp';
import placeholderTraining from '@img/placeholder_training.webp';
import placeholderGroomer from '@img/placeholder_groomer.webp';
import placeholderBreeding from '@img/placeholder_breeding.webp';
import placeholderCrematorium from '@img/placeholder_crematorium.webp';
import placeholderLocal from '@img/placeholder_local.webp';
import placeholderOthers from '@img/placeholder_others.webp';
import c_dog_breeding from '@img/c_dog-breeding.webp';
import c_dog_daycare from '@img/c_dog-daycare.webp';
import c_pet_sitter from '@img/c_pet-sitter.webp';
import c_dog_friendly_park from '@img/c_dog-friendly-park.webp';
import c_dog_friendly_bar from '@img/c_dog-friendly-bar.webp';
import c_dog_friendly_cafe from '@img/c_dog-friendly-cafe.webp';
import c_dog_friendly_hotel from '@img/c_dog-friendly-hotel.webp';
import c_dog_friendly_restaurant from '@img/c_dog-friendly-restaurant.webp';
import c_dog_friendly_shopping_center from '@img/c_dog-friendly-shopping-center.webp';
import c_dog_grooming from '@img/c_dog-grooming.webp';
import c_dog_park from '@img/c_dog-park.webp';
import c_dog_rescue from '@img/c_dog-rescue.webp';
import c_dog_training from '@img/c_dog-training.webp';
import c_dog_walking from '@img/c_dog-walking.webp';
import c_experiences from '@img/c_experiences.webp';
import c_pet_boarding from '@img/c_pet-boarding.webp';
import c_pet_crematorium from '@img/c_pet-crematorium.webp';
import c_pet_legal from '@img/c_pet-legal.webp';
import c_pet_shelter from '@img/c_pet-shelter.webp';
import c_pet_store from '@img/c_pet-store.webp';
import c_veterinarian from '@img/c_veterinarian.webp';
import c_pet_bakery from '@img/c_pet-bakery.svg';
import c_pet_photographer from '@img/c_pet-photographer.svg';
import c_pet_party from '@img/c_pet-party.svg';
import c_animal_massage from '@img/c_animal-massage.svg';
import c_animal_reiki from '@img/c_animal-reiki.svg';
import small from '@img/small-pet.webp';
import medium from '@img/medium-pet.webp';
import large from '@img/large-pet.webp';
import xlarge from '@img/xlarge-pet.webp';

export const loginGoogleUrl = `${process.env.REACT_APP_ROOT}/user/google/login`;
export const registerGoogleUrl = `${process.env.REACT_APP_ROOT}/user/google/register`;

export const options = [
    {value:"físico", label:"Físico"}, {value:"vitrual", label:"Vitrual"}, {value:"a domicilio", label:"A domicilio"}
]
export const amountPlaceholders = [1,2,3,4,5]

export  const defaultProps = { center: { lat: -12.085845, lng: -76.977371 }, zoom: 14 };

export const iconStoreMapping = {
    "pet-store": lc_pet_store, "veterinarian": lc_veterinaria, "dog-rescue": lc_veterinaria,
    "dog-park": lc_dog_park, "dog-friendly-park": lc_park_pet_friendly, "dog-walking": lc_training,
    "dog-training": lc_training, "dog-grooming": lc_groomer, "dog-daycare": lc_groomer,
    "pet-boarding": lc_groomer, "pet-sitter": lc_groomer, "dog-breeding": lc_breeding,
    "pet-shelter": lc_breeding, "dog-friendly-bar": lc_local, "dog-friendly-cafe": lc_local,
    "dog-friendly-restaurant": lc_local, "dog-friendly-store": lc_local, "pet-crematorium": marker,
    "pet-legal": marker, "dog-friendly-hotel": lc_other, "dog-friendly-shopping-center": lc_other,
    "pet-photographer": lc_dog_park, "pet-party": lc_groomer, "animal-massage": lc_groomer,
    "animal-reiki": lc_groomer,
};

export const storeValidValues = [
    "pet-store", "veterinarian", "dog-park", "dog-friendly-park", "dog-walking", "dog-training",
    "dog-grooming", "dog-breeding", "pet-crematorium", "pet-shelter", "dog-rescue", "dog-friendly-bar",
    "dog-friendly-cafe", "dog-friendly-shopping-center", "dog-friendly-hotel", "dog-daycare", "pet-boarding",
    "pet-legal", "pet-sitter", "dog-friendly-restaurant", "dog-friendly-store", "pet-photographer",
    "pet-party", "animal-massage", "animal-reiki"
];

export const storePlaceholderMap = {
    "pet-store": placeholderPetshop, "veterinarian": placeholderVet, "dog-rescue": placeholderVet,
    "dog-park": placeholderPark, "dog-friendly-park": placeholderParkDogFriendly, "dog-walking": placeholderTraining,
    "dog-training": placeholderTraining, "dog-grooming": placeholderGroomer, "pet-sitter": placeholderGroomer,
    "dog-breeding": placeholderBreeding, "pet-shelter": placeholderBreeding, "dog-friendly-bar": placeholderLocal,
    "dog-friendly-cafe": placeholderLocal, "dog-friendly-restaurant": placeholderLocal, "dog-friendly-store": placeholderLocal,
    "pet-crematorium": placeholderCrematorium, "dog-friendly-hotel": placeholderOthers, "dog-friendly-shopping-center": placeholderOthers,
    "pet-photographer": placeholderPark, "pet-party": placeholderGroomer, "animal-massage": placeholderGroomer,
    "animal-reiki": placeholderGroomer,
};

export const categoryImages = {
    c_dog_breeding, c_dog_daycare, c_dog_friendly_park, c_dog_friendly_bar, c_dog_friendly_cafe,
    c_dog_friendly_hotel, c_dog_friendly_restaurant, c_dog_friendly_shopping_center, c_dog_grooming,
    c_dog_park, c_dog_rescue, c_dog_training, c_experiences, c_pet_boarding, c_pet_crematorium,
    c_pet_legal, c_pet_shelter, c_pet_store, c_veterinarian, c_pet_sitter, c_pet_bakery, c_dog_walking,
    c_pet_photographer, c_pet_party, c_animal_massage, c_animal_reiki
};

export const arraySize = [
    {img: small, label: "Pequeño", value:'s'},
    {img: medium, label: "Mediano",  value:'m'},
    {img: large, label: "Grande",  value:'l'},
    {img: xlarge, label: "Extra Grande",  value:'xl'},
]